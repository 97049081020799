.spinning.glyphicon {
    margin-right: 7px;
    top: 2px;
    animation: spin 1s infinite linear;
}
@keyframes spin {
    from { transform: scale(1) rotate(0deg); }
    to { transform: scale(1) rotate(360deg); }
}

.removeButton {
    top:3px;
    float: right;
    z-index: 300;
}

.removeButton:hover {
    color: red;
}

.editButton {
    top:3px;
    float: right;
    z-index: 300;
}

.editButton:hover {
    color: dodgerblue;
}

.progressBar {
    float: left;
    width: 90%;
}
.nopadding {
    padding: 0;
    margin: 0;
}